<template>
  <div class="positionSidebar">
    <v-navigation-drawer
      v-if="!isMobile"
      :color="color"
      :permanent="true"
      app
      floating
      style="padding: 0"
    >
      <div>
        <div class="p-20 d-flex justify-center align-center white--text">
          <img
            src="../../assets/img/logo_kesling.png"
            class="mr-3"
            height="60px"
            alt=""
          />
        </div>
        <div class="p-20 mb-5">
          <p class="white--text">
            Selamat datang di Sistem Informasi Kelola Limbah Medis
          </p>
          <v-btn
            block
            class="text-capitalize"
            depressed
            color="white"
            outlined
            @click="showLogin"
            >masuk</v-btn
          >
        </div>
        <div class="sidebar_item mb-1" v-for="(item, idx) in menu" :key="idx">
          <router-link :to="item.path" style="text-decoration: none">
            <div
              :class="
                $route.path == item.path ? 'sidebar_active' : 'sidebar_unactive'
              "
            >
              <v-icon
                class="mr-3"
                :color="$route.path == item.path ? '#00b4cc' : 'white'"
                :dark="false"
                >{{ item.icon }}</v-icon
              >
              <p class="ma-0">{{ item.name }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="poweredTxtSidebar">
        <p class="ml-8" style="margin-bottom: 4px">
          Powered by piarea.co.id, <br />
          supported by WHO Indonesia
        </p>
        <span style="font-weight: 600" class="text-left ml-8">
          SIKELIM v1.3
        </span>
      </div>

      <Login v-bind:dialogLogin="dialogLogin" @close="dialogLogin = false" />
    </v-navigation-drawer>

    <v-app-bar
      style="height: auto !important"
      class="pa-3"
      v-if="isMobile"
      app
      color="primary"
      dark
      elevation="0"
    >
      <img
        src="../../assets/img/logo_kesling.png"
        class="mr-3"
        height="50px"
        alt=""
      />

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click="toggleSlider">
        <v-icon>{{ sliderOpen ? "mdi-close" : "mdi-menu" }}</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <div v-if="isMobile" class="topMobileContainer">
      <div class="pa-2">
        <h3 class="mt-6">
          Selamat datang di Sistem <br />
          Informasi Kelola Limbah Medis
        </h3>
      </div>
      <div class="btnMobile">
        <v-btn
          block
          class="text-capitalize customBtn"
          color="white"
          outlined
          @click="showMobileLogin"
          >masuk</v-btn
        >
      </div>
    </div>

    <MobileLogin
      v-bind:dialogMobileLogin="dialogMobileLogin"
      @close="dialogMobileLogin = false"
    />

    <div
      v-if="isMobile && sliderOpen"
      class="dark-overlay"
      @click="toggleSlider"
    ></div>
    <v-slide-y-transition>
      <v-container
        v-if="isMobile && sliderOpen"
        fluid
        class="custom-slider"
        @click.stop="toggleSlider"
      >
        <div class="sidebar_item mb-1" v-for="(item, idx) in menu" :key="idx">
          <router-link :to="item.path" style="text-decoration: none">
            <div
              :class="
                $route.path == item.path ? 'sidebar_active' : 'sidebar_unactive'
              "
            >
              <v-icon
                class="mr-3"
                :color="$route.path == item.path ? '#00b4cc' : 'white'"
                :dark="false"
                >{{ item.icon }}</v-icon
              >
              <p class="ma-0">{{ item.name }}</p>
            </div>
          </router-link>
        </div>
        <div class="poweredTxt">
          <p>
            Powered by piarea.co.id, <br />
            supported by WHO in Indonesia
          </p>
        </div>
      </v-container>
    </v-slide-y-transition>
  </div>
</template>

<script>
import Login from "../../views/Auth/Login.vue";
import MobileLogin from "../../views/Auth/MobileLogin.vue";
export default {
  components: { Login, MobileLogin },

  name: "publicSidebar",
  data() {
    return {
      color: "#00b4cc",
      menu: [
        {
          name: "Beranda",
          path: "/welcome",
          icon: "mdi-home-outline",
        },
        {
          name: "Dokumen",
          path: "/public/document",
          icon: "mdi-cloud-download-outline",
        },
        {
          name: "Tentang",
          path: "/public/about",
          icon: "mdi-information-outline",
        },
        {
          name: "Panduan",
          path: "/public/guide",
          icon: "mdi-information-outline",
        },
      ],
      dialogLogin: false,
      dialogMobileLogin: false,
      isMobile: false,
      sliderOpen: false,
    };
  },
  mounted() {
    // Check if the screen is mobile on mount
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    showLogin() {
      this.dialogLogin = true;
    },
    showMobileLogin() {
      this.dialogMobileLogin = true;
    },
    toggleSlider() {
      this.sliderOpen = !this.sliderOpen;
    },

    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
      if (!this.isMobile) {
        this.modalOpen = false; // Close the modal on larger screens
      }
    },
    handleButtonClick(menuNumber) {
      // Handle button click based on menuNumber
      console.log(`Clicked Menu ${menuNumber}`);
      this.sliderOpen = !this.sliderOpen;
    },
  },
};
</script>

<style scoped>
.positionSidebar {
  position: absolute;
}
.poweredTxtSidebar {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #eff2f8;
  justify-content: center;
  bottom: 0px;
  position: absolute;
  width: 100%;
  margin-bottom: 40px;
}

.poweredTxtSidebar p {
  font-weight: 300;
  text-align: left;
}

.sidebar_item {
  padding-left: 20px;
}
.sidebar_active {
  display: flex;
  padding: 15px 20px;
  border-radius: 10px 0 0 10px;
  background: white;
  color: #00b4cc;
  cursor: pointer;
}
.sidebar_unactive {
  display: flex;
  padding: 17px 20px;
  border-radius: 10px 0 0 10px;
  color: #fff;
  cursor: pointer;
}
.logo {
  padding: 20px;
}
.p-20 {
  padding: 20px;
}

/* mobile view css */
@media only screen and (max-width: 600px) {
  .positionSidebar {
    position: unset;
  }

  .sidebar_item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar_active {
    display: flex;
    padding: 15px 20px;
    border-radius: 10px 10px 10px 10px;
    background: white;
    color: #00b4cc;
    cursor: pointer;
  }
  .sidebar_unactive {
    display: flex;
    padding: 17px 20px;
    border-radius: 10px 0 0 10px;
    color: #fff;
    cursor: pointer;
  }
}

.poweredTxt {
  display: flex;
  font-size: 12px;
  color: #eff2f8;
  justify-content: center;
  border-top: #fff solid 2px;
  margin-top: 25px;
}

.poweredTxt p {
  margin-top: 25px;
  text-align: center;
}
.custom-slider {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 40vh;
  background-color: #00b4cc;
  color: white;
  overflow-y: hidden;
  z-index: 1000;
  transition: top 0.3s ease;
}

.dark-overlay {
  position: fixed;
  top: 85px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.topMobileContainer {
  height: 220px;
  background-color: #00b4cc;
  color: #fff;
  padding: 10px;
}
.btnMobile {
  width: 205px;
  height: 50px;
  margin-left: 8px;
}

.customBtn {
  height: 50px !important;
  border-radius: 8px;
  margin-top: 8px;
}
</style>
