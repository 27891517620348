<template>
<div>
  <v-navigation-drawer
    v-if="!isMobile"
    :color="color"
    :permanent="true"
    app
    floating
    style="padding: 0"
    class="scroller"
  >
    <div>
      <div class="justify-center p-20 d-flex align-center white--text">
        <img
          src="../../assets/img/logo_kesling.png"
          class="mr-3"
          height="50px"
          alt=""
        />
      </div>
      <div class="p-20 mb-5 d-flex">
        <div
          class="justify-center frame_img d-flex align-center"
          style="width: 100px"
        >
          <img
            :src="dummy"
            class="pp"
            alt=""
            style="border-radius: 50px; width: 48px"
            height="50px;"
          />
        </div>
        <div class="white--text">
          <p class="small_txt ma-0">Fasyankes</p>
          <p class="small_txt ma-0">
            <b>
              <b>{{ profile.profile.full_name }}</b>
            </b>
          </p>
          <router-link
            to="/profile"
            style="text-decoration: none"
          >
            <p class="small_txt ma-0 white--text">profil</p>
          </router-link>
        </div>
      </div>
      <div v-if="!configMenu">
        <div
          class="mb-1 sidebar_item"
          v-for="(item, idx) in menu"
          :key="idx"
        >
          <router-link
            :to="item.path"
            style="text-decoration: none"
          >
            <div :class="
                $route.path == item.path ? 'sidebar_active' : 'sidebar_unactive'
              ">
              <v-icon
                class="mr-5"
                :color="$route.path == item.path ? '#00b4cc' : 'white'"
                :dark="false"
              >{{ item.icon }}</v-icon>
              <p class="ma-0 hovering_txt">{{ item.name }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="pa-3">
        <v-divider style="background: #fff"></v-divider>
      </div>
      <div class="mb-1 sidebar_item">
        <router-link
          to="/user/notification"
          style="text-decoration: none"
        >
          <div :class="$route.path == '/user/notification' ? 'sidebar_active' : 'sidebar_unactive'">
            <NotificationBadge :on_focus="$route.path == '/user/notification'" />
            <p class="ma-0 hovering_txt">Notifikasi</p>
          </div>
        </router-link>
      </div>
      <div class="mb-1 sidebar_item">
        <div
          class="sidebar_unactive"
          @click="logout"
        >
          <v-icon
            class="mr-5"
            color="white"
            style="transform: scale(-1, 1)"
          >mdi-logout-variant</v-icon>
          <p class="ma-0 hovering_txt">Keluar</p>
        </div>
      </div>

    </div>

    <div class="poweredTxtSidebar">
        <p class="ml-8" style="margin-bottom: 4px;">
          Powered by piarea.co.id, <br>
          supported by WHO Indonesia
        </p>
        <span style="font-weight: 600;" class="text-left ml-8 ">
          SIKELIM v1.3
        </span>
    </div>
  </v-navigation-drawer>

    <v-app-bar style="height: auto !important;" class="pa-3" v-if="$isMobile()" app color="primary" dark elevation="0">
        
        <img
      src="../../assets/img/logo_kesling.png"
      class="mr-3"
      height="50px"
      alt=""
      />
      
      <v-spacer></v-spacer>
      
      <v-app-bar-nav-icon @click="toggleSlider">
        <v-icon>{{ sliderOpen ? 'mdi-close' : 'mdi-menu' }}</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <div v-if="$isMobile()" class="topMobileContainer">
      <div class="p-20 mt-5 d-flex">
        <div
          class="justify-center frame_img d-flex align-center"
          style="width: 100px"
        >
          <img
            :src="dummy"
            class="pp"
            alt=""
            style="border-radius: 50px; width: 48px"
            height="50px;"
          />
        </div>
        <div class="white--text">
          <p class="small_txt ma-0">Fasyankes</p>
          <p class="small_txt ma-0">
            <b>
              <b>{{ profile.profile.full_name }}</b>
            </b>
          </p>
          <router-link
            to="/profile"
            style="text-decoration: none"
          >
            <p class="small_txt ma-0 white--text">profil</p>
          </router-link>
        </div>
      </div>
      
    </div>
    
  
    <div v-if="isMobile && sliderOpen" class="dark-overlay" @click="toggleSlider"></div>
    <v-slide-y-transition>
      <v-container
        v-if="isMobile && sliderOpen"
        fluid
        class="custom-slider"
        @click.stop="toggleSlider"
      >
        <!-- Slider container content -->
        <div class="mb-1 sidebar_item" v-for="(item, idx) in mobileMenu" :key="idx">
          <router-link :to="item.path" style="text-decoration: none">
            <div
              :class="
                $route.path == item.path ? 'sidebar_active' : 'sidebar_unactive'
              "
            >
              <v-icon
                class="mr-3"
                :color="$route.path == item.path ? '#00b4cc' : 'white'"
                :dark="false"
                >{{ item.icon }}</v-icon
              >
              <p class="ma-0">{{ item.name }}</p>
            </div>
          </router-link>
        </div>
        <div class="pt-2 poweredTxt">
          <div class="mb-1 sidebar_item">
            <router-link
              to="/user/notification"
              style="text-decoration: none"
            >
              <div :class="$route.path == '/user/notification' ? 'sidebar_active' : 'sidebar_unactive'">
                <NotificationBadge :on_focus="$route.path == '/user/notification'" />
                <p class="ma-0 hovering_txt">Notifikasi</p>
              </div>
            </router-link>
          </div>
          <div class="mb-1 sidebar_item">
            <div
              class="sidebar_unactive"
              @click="logout"
            >
              <v-icon
                class="mr-3"
                color="white"
              >mdi-logout-variant</v-icon>
              <p class="ma-0">Keluar</p>
            </div>
          </div>
          <p style="font-size: 12px;">
            Powered by piarea.co.id, <br>
            supported by WHO in Indonesia
          </p>
        </div>
      </v-container>
    </v-slide-y-transition>
</div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import NotificationBadge from "../../components/menu/NotificationBadge";

export default {
  name: "fasyankesSidebar",
  components: {
    NotificationBadge,
  },
  computed: {
    ...mapState({
      profile: (state) => state.user,
      dummy: (state) => state.imgDummy,
    }),
  },
  data() {
    return {
      isMobile: false,
      sliderOpen: false,
      configMenu: false,
      color: "#00b4cc",
      menu: [
        {
          name: "Formulir",
          path: "/formulir",
          icon: "mdi-format-list-bulleted",
        },
        {
          name: "Peta",
          path: "/",
          icon: "mdi-map",
        },

        {
          name: "Tabel",
          path: "/table/fasyankes",
          icon: "mdi-clipboard-pulse-outline",
        },
        {
          name: "Dokumen",
          path: "/document/user",
          icon: "mdi-cloud-download-outline",
        },
        {
          name: "Tentang",
          path: "/about/user",
          icon: "mdi-information-outline",
        },
        {
            name: "Panduan",
            path: "/guide/user",
            icon: "mdi-information-outline",
          },
      ],
      mobileMenu: [
        {
          name: "Beranda",
          path: "/",
          icon: "mdi-home",
        },
        {
          name: "Formulir",
          path: "/formulir",
          icon: "mdi-format-list-bulleted",
        },
        
        {
          name: "Dokumen",
          path: "/document/user",
          icon: "mdi-cloud-download-outline",
        },
        {
          name: "Tentang",
          path: "/about/user",
          icon: "mdi-information-outline",
        },
        {
            name: "Panduan",
            path: "/guide/user",
            icon: "mdi-information-outline",
          },
      ],
      dialogLogin: false,
    };
  },
  mounted() {
    this.getPhotoProfile();
    this.checkMobile();
  },
  methods: {
    toggleSlider() {
      this.sliderOpen = !this.sliderOpen;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
      if (!this.isMobile) {
        this.modalOpen = false; // Close the modal on larger screens
      }
    },
    getPhotoProfile() {
      this.$store.dispatch("getPhotoProfile").then((data) => {});
    },
    showLogin() {
      this.dialogLogin = true;
    },
    logout() {
      Cookies.remove("token");
      Cookies.remove("id");
      Cookies.remove("role");
      this.$router.go("/");
    },
    swipeConfig() {},
    handleButtonClick(menuNumber) {
      // Handle button click based on menuNumber
      console.log(`Clicked Menu ${menuNumber}`);
      this.sliderOpen = !this.sliderOpen;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Montserrat";
}

.poweredTxtSidebar {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #EFF2F8;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.poweredTxtSidebar p {
  font-weight: 300;
  text-align: left;
  
}

.sidebar_item {
  padding-left: 20px;

  font-weight: 500;
}
.sidebar_subitem {
  padding-left: 30px;
}
.sidebar_active {
  display: flex;
  padding: 15px 20px;
  border-radius: 10px 0 0 10px;
  background: white;
  color: #00b4cc;
  cursor: pointer;
}
.sidebar_unactive {
  display: flex;
  padding: 17px 20px;
  border-radius: 10px 0 0 10px;
  color: #fff;
  cursor: pointer;
}
.logo {
  padding: 20px;
}
.p-20 {
  padding: 20px;
}
.hovering_txt:hover {
  font-weight: bold;
}
::-webkit-scrollbar {
  width: 10px;
}
.dp {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
  background: #6cd8e6;
}

/* mobile view css */
@media only screen and (max-width: 600px) {
  .sidebar_item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar_active {
    display: flex;
    padding: 15px 20px;
    border-radius: 10px 10px 10px 10px;
    background: white;
    color: #00b4cc;
    cursor: pointer;
  }
  .sidebar_unactive {
    display: flex;
    padding: 17px 20px;
    border-radius: 10px 0 0 10px;
    color: #fff;
    cursor: pointer;
  }
 
}

.poweredTxt {
  display: flex;
  flex-direction: column;
  color: #EFF2F8;
  justify-content: center;
  border-top: #fff solid 2px;
  margin-top: 10px;
  
}

.poweredTxt p {
  margin-top: 5px;
  text-align: center;
  
}
.custom-slider {
  position: fixed;
  top: 80px; 
  left: 0;
  width: 100%;
  height: 60vh;
  background-color: #00b4cc;
  color: white;
  overflow-y: hidden;
  z-index: 1000;
  transition: top 0.3s ease;
}

.dark-overlay {
  position: fixed;
  top: 85px; 
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 999;
}

.topMobileContainer {
  height: 150px; 
  background-color: #00b4cc;
  color: #fff;
  padding: 10px;
 
}
.btnMobile {
  width: 205px; 
  height: 50px;
  margin-left: 8px;
}

.customBtn {
  height: 50px !important; 
  border-radius: 8px;
  margin-top: 8px;
}
</style>