import Axios from "axios";
import Cookies from "js-cookie";
import Vue from "vue";
import Vuex from "vuex";
import auth from "./Module/Auth";
import data from "./Module/Data";
import grafik from "./Module/Grafik";
import dataFasyankes from "./Module/dataFasyankes";
import document from "./Module/document";
import form from "./Module/form";
import formulir from "./Module/formulir";
import globalUI from "./Module/globalUI";
import instansi from "./Module/instansi";
import map from "./Module/map";
import notifikasi from "./Module/notifikasi";
import region from "./Module/region";
import report from "./Module/report";
import tentang from "./Module/tentang";
import user from "./Module/user";
import file from "./Module/file";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // url: "https://sikelimkemkes.id/api/public/",
    url: process.env.VUE_APP_ENV + "/",
    token: Cookies.get("token") || null, //token
    role: Cookies.get("role"),
    imgDummy: require("../assets/img/dummyprofile.svg"),
    getIdentitas: null,
    getBor: null,
    reportId: null,
    postBor: null,
    savedMonth: null,
    savedYear: null,
  },
  getters: {
    getUser(state) {
      return state.token !== null && state.token !== undefined;
    },
    getUserRole(state) {
      return state.role;
    },
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },
    POST_BOR(state, data) {
      state.postBor = data;
    },
    GET_REPORT_ID(state, data) {
      state.reportId = data;
    },
    GET_BOR(state, data) {
      state.getBor = data;
    },
    GET_IDENTITAS(state, data) {
      state.getIdentitas = data;
    },
    GET_MONTHS(state, data) {
      // Do nothing, just for postApi comply.
    },
    SET_IMGDUMMY(state, data) {
      state.imgDummy = data;
    },
    SAVE_MONTH(state, data) {
      state.savedMonth = data;
    },
    SAVE_YEAR(state, data) {
      state.savedYear = data;
    },
  },
  actions: {
    getPhotoProfile(context) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = {
          header: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };
        Axios.get(`${env}/api/pp?id=${Cookies.get("id")}`, config)
          .then((res) => {
            let data = res.data;

            context.commit(
              "SET_IMGDUMMY",
              data.data.user_image != "null"
                ? "https://api.sikelimkemkes.id/storage/profil/" +
                    data.data.user_image
                : require("../assets/img/dummyprofile.svg")
            );
            // context.commit("SET_IMGDUMMY", data.data.user_image != null ? "https://sikelimkemkes.id/api/public/storage/profil/"+data.data.user_image : require("../assets/img/dummyprofile.svg"));
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getApi({ commit }, proto) {
      return new Promise((resolve, reject) => {
        Axios.get(`${this.state.url}${proto.url}`, proto.headers)
          .then((res) => {
            console.log(res);
            resolve(res.data);

            commit(proto.mutation, res.data);
          })
          .catch((err) => {
            reject(new Error(err));
          });
      });
    },
    postApi({ commit }, proto) {
      return new Promise((resolve, reject) => {
        Axios.post(`${this.state.url}${proto.url}`, proto.data, proto.headers)
          .then((res) => {
            // commit(proto.Loading)
            // if (res.data.success) {
            //   Swal.fire("Berhasil", `${res.data.message}`, "success")
            //     .then(() => {
            //       window.location.reload();
            //     })
            //     .catch(() => {
            //       window.location.reload();
            //     });
            // } else if (res.data.message == "Unauthorized") {
            //   Swal.fire(
            //     "Sesi habis!",
            //     "Mohon melakukan login ulang",
            //     "warning"
            //   ).then(() => {
            //     window.location.replace("/");
            //     localStorage.clear();
            //   });
            // } else {
            //   Swal.fire("Gagal", `${res.data.message}`, "warning");
            // }
            commit(proto.mutation, res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(new Error(err));
          });
      });
    },
    putApi({ commit }, proto) {
      return new Promise((resolve, reject) => {
        Axios.put(`${this.state.url}${proto.url}`, proto.data, proto.headers)
          .then((res) => {
            commit(proto.mutation, res);
          })
          .catch((err) => {
            if (err) {
              reject(new Error(err));
            }
          });
      });
    },
  },
  modules: {
    auth,
    user,
    data,
    region,
    formulir,
    tentang,
    document,
    instansi,
    notifikasi,
    map,
    form,
    dataFasyankes,
    grafik,
    report,
    globalUI,
    file,
  },
});
