export const FASYANKES_TYPE = {
    Hospital: 1, // Rumah Sakit
    PublicHealthCenter: 2, // Puskesmas
}

export const FASYANKES_TYPE_NAME = {
    1: 'Rumah Sakit',
    2: 'Puskesmas',
}

export const ROLES = {
    PublicHealthService_Province: "Dinkes Provinsi",
    PublicHealthService_Regency: "Dinkes Kota",
    HealthServiceFacility: 'Fasyankes',
    Administrator: 'Administrator',
    MinistryOfHealth: 'Kemenkes',
    External: 'Eksternal',
};

export const REGION_CHARACTERISTICS = {
    VeryRemote: 'Sangat Terpencil',
    Remote: 'Terpencil',
    Rural: 'Perdesaan',
    Urban: 'Perkotaan',
}

export const FORM_FILL_STATUS = {
    Unfilled: "unfilled",
    Unsend: "unsend",
    Sent: "sent",
    Confirmed: "confirmed",
    NeedRevision: "revise",
}