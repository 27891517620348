import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const formulir = {
  namespaced: true,
  state: {
    errMsg: "",
    province: "",
    city: "",
    person: "",
    active_user: "",
    selected_id: "",
    limbah_fasyankes: null,
    get_formulir_covid: null,
    get_formulir_sanitarian: null,
    pertanyaan_kunci: null,
    kesling: null,
    get_konfirmasi_fasyankes: null,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_REPORT_ID(state, data) {
      state.selected_id = data
    },
    GET_LIMBAH_FASYANKES(state, data) {
      state.limbah_fasyankes = data.data;
    },
    GET_FORMULIR_COVID(state, data) {
      state.get_formulir_covid = data.data;
    },
    GET_FORMULIR_SANITARIAN(state, data) {
      state.get_formulir_sanitarian = data;
    },
    GET_PERTANYAAN_KUNCI(state, data) {
      state.pertanyaan_kunci = data.data;
    },
    GET_KESLING(state, data) {
      state.kesling = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
    getDataFasyankes(state, data) {
      state.get_konfirmasi_fasyankes = data;
    },
  },
  actions: {
    filterAllinOne(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let params = data.body;
        let endpoint = "";
        let role = params.role == 0 ? "" : params.role;

        endpoint = `api/konfirmasi-formulir/list?tipe_form=${params.tipe_form}&nama_instansi=${params.find}&tipe_fasyankes=${role}&halaman=${params.limit}&page=${params.page}`;
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${endpoint}`, config)
          .then((res) => {
            let data = res;
            context.commit("getDataFasyankes", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateBor(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/updateBor`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", res);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getBor(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/showBor`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getIdentitas(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/profile`, config)
          .then((res) => {
            let data = res;
            context.commit("RESPONSE", res);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIMBAH FASYANKES
    createFormulirFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/limbahfasyankes`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getFormulirFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/limbahfasyankes?report_id=${data.report_id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_LIMBAH_FASYANKES", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    updateFormulirFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/limbahfasyankes`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    saveFormulirFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/limbahfasyankes/kirim`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIMBAH COVID FORMULIR
    createDataFormulirCovid(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/limbahcovid`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    createDataFormulirSanitarian(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/sanitarian-kit`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getDataFormulirCovid(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/limbahcovid?report_id=${data.report_id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_FORMULIR_COVID", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getDataFormulirSanitarian(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/sanitarian-kit?report_id=${data.report_id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_FORMULIR_SANITARIAN", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateDataFormulirCovid(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/limbahcovid`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    saveDataFormulirCovid(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/limbahcovid/simpan`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIST DATE FORMULIR COVID
    listDateFormulirCovid(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/limbahcovid/list-kalender-report`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getlistKunci(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/kesehatanlingkungan-pertanyaankunci/listbulan`, data, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // PERTANYAAN KUNCI
    createPertanyaanKunci(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/kesehatanlingkungan-pertanyaankunci`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getPertanyaanKunci(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/kesehatanlingkungan-pertanyaankunci?report_id=${data.report_id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_PERTANYAAN_KUNCI", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updatePertanyaanKunci(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/kesehatanlingkungan-pertanyaankunci`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    savePertanyaanKunci(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/kesehatanlingkungan-pertanyaankunci/kirim`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // KESEHATAN LINGKUNGAN BIASA
    createKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/kesehatanlingkungan`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/kesehatanlingkungan?report_id=${data.report_id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_KESLING", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/kesehatanlingkungan`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    saveKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/kesehatanlingkungan/kirim`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // SANITARIAN KIT
    getKalenderSanitarian(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/sanitarian-kit/list-kalender-report`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            context.commit("ERR_MSG", err);
            reject(err);
          });
      });
    },
    updateDataFormulirSanitarian(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/sanitarian-kit`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    saveDataFormulirSanitarian(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/sanitarian-kit/simpan`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIST BULAN FASYANKES
    listMonthFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/limbahfasyankes/listbulan`, data, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIST BULAN ALKES BERMERKURI
    listMonthAlkesBermerkuri(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/alkesbermerkuri/listbulan`, data, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LIST BULAN KESEHATAN LINGKUNGAN
    listMonthKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/kesehatanlingkungan/listbulan`, data, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // ================================================== post file alkes bermerkuri
    updateFileMerkuri(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/upload-alkes-bermerkuri`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // ==============================================================================-Konfirmasi formulir-===================================================================
    // KONFIRMASI FORMULIR FASYANKES
    createBeforeKonfirmasi(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/form-confirmation`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // KONFIRMASI FORMULIR KESLING
    createBeforeKonfirmasiKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_API_BASE_URL;
        axios
          .post(`${env}/api/form-confirmation/kesehatan-lingkungan`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // STATUS2 KONFIRMASI FORMULIR
    getDetailConfirm(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/form-confirmation?report_id=${data}`, config)
          .then((res) => {
            let data = res.data.data;
            // context.commit("GET_LIMBAH_FASYANKES", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getDetailConfirmKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/form-confirmation/kesehatan-lingkungan?report_id=${data}`, config)
          .then((res) => {
            let data = res.data.data;
            // context.commit("GET_LIMBAH_FASYANKES", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LETS KONFIRM FASYANKES!
    confirm(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/form-confirmation/simpan`, data, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // LETS KONFIRM KESLINF!
    confirmKesling(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/form-confirmation/kesehatan-lingkungan/simpan`, data, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // HASIL KONFIRM
    resultConfirm(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/perbaikan-form?report_id=${data}`, config)
          .then((res) => {
            let data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // Data Limbah Fasyankes From API external
    getDataLimbahFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_API_BASE_URL;
        axios
          .get(`${env}/api/limbahfasyankes/meSmile?report_id=${data.report_id}`, config)
          .then((res) => {
            let data = res.data;
            console.log(`datanya`,data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
          
      });
      
    },

    

  },
};

export default formulir;
