<template>
    <v-row justify="start">
      <v-dialog transition="dialog-top-transition" v-model="dialogMobileLogin" scrollable fullscreen overlay>
        
        <v-container class="mobileContainer" >
            <v-card class="rounded-max hide_overflow" flat height="450px">
            <div class="d-flex flex-column">
                <div class="d-flex mx-auto">
                    <div
                        class="d-flex justify-center align-center"
                        style="height: 100%"
                    >
                        <div>
                        <div class="d-flex justify-center mt-8">
                            <img
                            src="../../assets/img/logo_kesling_old.png"
                            height="50px"
                            alt=""
                            />
                        </div>
                        <div class="white--text mt-3">
                        </div>
                        </div>
                    </div>
                </div>
            
                <div class="">
                
                <v-scroll-x-transition origin="top left 5">
                    <div class="temp_form" v-show="loginForm">
                    <div>
                        <h3 class="color_txt" v-if="!sso">Login</h3>
                        <h3 class="color_txt" v-else>SSO Login</h3>
                    </div>
                    <div class="form_login mt-5">
                        <form @submit.prevent="login">
                        <v-row>
                            <v-col cols="12">
                            <v-text-field
                                hide-details="auto"
                                outlined
                                label="Email"
                                v-model="email"
                                prepend-inner-icon="mdi-email"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                            <v-text-field
                                outlined
                                label="Password"
                                type="password"
                                v-model="password"
                                hide-details="auto"
                                prepend-inner-icon="mdi-lock"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                            <v-btn
                                v-if="!loading"
                                color="#00b4cc"
                                block
                                dark
                                class="text-capitalize"
                                type="login"
                                >Login</v-btn
                            >
                            <v-btn
                                v-else
                                color="#00b4cc"
                                block
                                dark
                                class="text-capitalize"
                                >Loading..</v-btn
                            >
                            </v-col>
                        </v-row>
                        </form>
                        <v-divider class="mt-3"></v-divider>
                        <div class="d-flex justify-content-between">
                        <v-btn
                            text
                            class="text-capitalize"
                            v-if="!sso"
                            @click="sso = true"
                        >
                            <small class="grey--text">SSO Login </small>
                        </v-btn>

                        <v-btn
                            text
                            class="text-capitalize"
                            v-else
                            @click="sso = false"
                        >
                            <small class="grey--text">Normal Login</small>
                        </v-btn>

                        <v-btn
                            text
                            class="text-capitalize"
                            @click="loginForm = false"
                        >
                            <small class="grey--text">Lupa Password</small>
                        </v-btn>
                        </div>
                    </div>
                    </div>
                </v-scroll-x-transition>
                <v-scroll-x-transition origin="top left 5">
                    <div class="temp_form" v-show="!loginForm">
                    <div>
                        <h3 class="color_txt">Lupa Password?</h3>
                    </div>
                    <div class="form_login mt-5">
                        <form @submit.prevent="login">
                        <v-row>
                            <v-col cols="12">
                            <v-text-field
                                hide-details="auto"
                                outlined
                                label="Email"
                                v-model="email"
                                prepend-inner-icon="mdi-email"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                            <v-btn
                                v-if="!loading"
                                color="#00b4cc"
                                block
                                dark
                                class="text-capitalize py-6"
                                type="login"
                                >Submit</v-btn
                            >
                            <v-btn
                                v-else
                                color="#00b4cc"
                                block
                                dark
                                class="text-capitalize"
                                >Loading..</v-btn
                            >
                            </v-col>
                        </v-row>
                        </form>
                        <div style="height: 70px"></div>
                        <v-divider class="mt-3"></v-divider>
                        <div class="d-flex">
                        <v-btn
                            text
                            class="text-capitalize pa-0"
                            min-width="5px"
                            @click="loginForm = true"
                        >
                            <v-icon color="#7f7f7f">mdi-arrow-left</v-icon>
                            <small class="grey--text">Kembali ke login form</small>
                        </v-btn>
                        </div>
                    </div>
                    </div>
                </v-scroll-x-transition>
                
                </div>
                
            </div>
            </v-card>
            
            <v-card class="rounded-max transparent-card mt-10" height="100px">
                <v-btn class="btnClose" @click="$emit('close')">
                    <v-icon style="font-size: 40px; ">mdi-close</v-icon>
                </v-btn>
            </v-card>
        
        </v-container>
    
      </v-dialog>
      
    </v-row>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Swal from "sweetalert2";
  
  export default {
    name: "dialogMobileLogin",
    props: ["dialogMobileLogin"],
    computed: {
      ...mapState({
        err: (state) => state.auth.errMsg,
        solve: (state) => state.auth.response,
      }),
    },
    data() {
      return {
        email: "",
        password: "",
        loading: false,
        loginForm: true,
        sso: false,
      };
    },
    methods: {
      login() {
        let data = {
          username: this.email,
          password: this.password,
          "apps-key": "sikelim-qwhJuKx8781-Xkh9123",
          sso: this.sso,
        };
        this.loading = true;
        this.$store
          .dispatch("auth/login", data)
          .then((data) => {
            console.log(data);
            // this.loading = false;
            this.Swal("success", data.message);
            this.$router.go("/home");
          })
          .catch((err) => {
            console.log("err", err);
            this.loading = false;
            this.Swal("error", err.message);
          });
      },
      Swal(type, msg) {
        Swal.fire({
          icon: type,
          title: msg,
          showConfirmButton: false,
          position: "top-end",
          timer: 3000,
          toast: true,
          customClass: {
            title: "family",
          },
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .side_left {
    width: 40%;
    height: 400px;
    border-radius: 0 !important;
  }
  .side_right {
    width: 60%;
  }
  .closer {
    width: 100%;
  }
  .temp_form {
    padding: 20px 25px;
  }
  .hide_overflow {
    overflow: hidden;
  }
  .family {
    font-family: Arial, Helvetica, sans-serif;
  }
  .large_sikelim {
    font-size: 30px;
  }

  .transparent-card {
  background-color: transparent !important;
  box-shadow: none !important;
    }

    .mobileContainer {
    padding-right: 25px;
    padding-left: 25px;
    background-color: rgba(0, 0, 0, 0.6);
    flex-direction: column;
    display: flex;
    justify-content: center;
    
    }
    .dialog-top-transition-enter-active, .dialog-top-transition-leave-active {
    transition: transform 0.5s;
    }
    .dialog-top-transition-enter, .dialog-top-transition-leave-to {
    transform: translateY(-100%);
    }
    .btnClose {
        display: flex;
        color: white;
        margin-left: auto;
        margin-right: auto;
        background-color: transparent !important;
        box-shadow: none !important;
        
    }

  </style>