import axios from "axios";
import Cookies from "js-cookie";

const state = {
  namespaced: true,
  config: {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      withCredentials: true,
    },
  },

  actions: {
    upload(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/api/fileupload`, data, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
};

export default state;
