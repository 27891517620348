<template>
  <div>
    <v-badge
      v-if="show_with_count"
      class="mr-5 mt-1"
      :content="unread_notifications_count"
      :color="on_focus ? 'primary' : 'secondary'"
    >
      <v-icon :color="on_focus ? 'primary' : 'white'">mdi-bell-outline</v-icon>
    </v-badge>
    <v-icon
      v-else
            class="mr-5 mt-1"

      :color="on_focus ? 'primary' : 'white'"
    >mdi-bell-outline</v-icon>
  </div>

</template>

<script>
import { mapState } from "vuex";
import { ROLES } from "../../constants/constant";

export default {
  name: "NotificationBadge",
  computed: {
    ...mapState({
      role: (state) => state.role,
      unread_notifications_count: (state) =>
        state.notifikasi.unread_notifications_count,
    }),
    show_with_count() {
        const allowed_roles = [
            ROLES.PublicHealthService_Regency,
            ROLES.HealthServiceFacility,
        ];
        
        return this.unread_notifications_count > 0 && allowed_roles.includes(this.role);
    }
  },
  props: {
    on_focus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      local_count: this.count,
    };
  },
};
</script>