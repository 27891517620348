import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const dataFasyankes = {
  namespaced: true,
  state: {
    errMsg: "",
    tentang: "",
    detail: null,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    DETAIL(state, data) {
      state.detail = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    getFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        console.log(data);
        axios
          .get(`${env}/api/listfasyankes?tipe_fasyankes=${data.type}&halaman=${data.limit}&page=${data.page}`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    searchData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let type = data.type;
        let find = data.find;
        axios
          .get(`${env}/api/carifasyankes?tipe_fasyankes=${type}&find=${find}&halaman=${data.limit}&page=${data.page}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    detailFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        console.log(data);
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/detailfasyankes?tipe_fasyankes=${data.type}&id=${data.id}`, config)
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default dataFasyankes;
