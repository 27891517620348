import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const region = {
  namespaced: true,
  state: {
    errMsg: "",
    province: "",
    city: "",
    kecamatan: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_PROVINCE(state, data) {
      state.province = data.data;
    },
    GET_CITY(state, data) {
      state.city = data.data;
    },
    GET_KECAMATAN(state, data) {
      state.kecamatan = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    getProvinces(context) {
      return new Promise((resolve, reject) => {
        const config = context.state.config;
        axios
          .get(
            `${process.env.VUE_APP_API_BASE_URL}/api/data/provinsi/get`,
            config
          )
          .then((res) => {
            const data = res.data;
            context.commit("GET_PROVINCE", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // TODO(galuh): remove when all using getProvinces
    getProvince(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/data/provinsi/get`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_PROVINCE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getProvinceByid(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/data/provinsi/get-byid?provinsi_id=${data}`, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    searchRegion(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        if (data.type == "provinsi") {
          endpoint = `${env}/api/map/getprovinsi?${data.type}=${data.find}`;
        } else {
          endpoint = `${env}/api/map/getprovinsi?provinsi=${data.idProv}&${data.type}=${data.find}`;
        }
        axios
          .get(endpoint, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },  
    getCities(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/api/data/kab-kota/get-by-provinsi-id`, payload, context.state.config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_CITY", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // TODO(galuh): remove when all using getCities
    getCity(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;

        axios
          .post(`${env}/api/data/kab-kota/get-by-provinsi-id`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_CITY", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getKecamatan(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;

        axios
          .post(`${env}/api/data/kecamatan/get-kab-kota`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_KECAMATAN", data);
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getRs(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/rs?kabupatenkota_id=${data}`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getPuskesmas(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/puskesmas?kabupatenkota_id=${data}`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default region;
