import axios from "axios";
import Cookies from "js-cookie";
import { FORM_FILL_STATUS, ROLES } from "../../constants/constant";

// MANAGEMENT USER
const notifikasi = {
  namespaced: true,
  state: {
    // errMsg: "",
    // response: null,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
    unread_notifications_count: 0,
  },
  getters: {},
  mutations: {
    SET_UNREAD_NOTIFICATIONS_COUNT(state, data) {
      state.unread_notifications_count = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // Get Notifikasi
    getNotifikasi(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_API_BASE_URL;
        axios
          .get(`${env}/api/notification?type=${data.tipeNotif}`, config)
          .then((res) => {
            let data = res.data;
            let unread_count = 0;

            if (context.rootState.role === ROLES.HealthServiceFacility) {
              data.data.form_fillings.forEach((form_filling) => {
                if (
                  form_filling.fill_status === FORM_FILL_STATUS.Unfilled ||
                  form_filling.fill_status === FORM_FILL_STATUS.Unsend ||
                  form_filling.fill_status === FORM_FILL_STATUS.NeedRevision
                ) {
                  unread_count++;
                }
              });
            } else {
              data.data?.notifications?.forEach((notification) => {
                if (notification.related_model) {
                  unread_count++;
                }
              });
            }

            context.commit("RESPONSE", data);
            context.commit("SET_UNREAD_NOTIFICATIONS_COUNT", unread_count);
            resolve(res.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getFormFillingStatus(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/form-filling-status`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateToRead(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(
            `${env}/api/notification/${data.notificationId}/mark-as-read`,
            data,
            config
          )
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default notifikasi;
