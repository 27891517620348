import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00b4cc",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        danger: "#B0BEC5",
        mainBlue : "#31B4CC",
        mainGreen :"00ccb6",
        gray25: "#404040"
      },
    },
  },
});
