import Vue from "vue";
import VueRouter from "vue-router";
import { ROLES } from "../constants/constant.js";
import store from "../store/index.js";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/sso-callback',
    name: 'SSOCallback',
    component: () => import('../views/Auth/SSOCallback.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Beranda/Public/Beranda.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/welcome",
        name: "Landing",
        component: () => import("../views/Beranda/Public/Beranda.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/admin/document",
        name: "Document",
        component: () => import("../views/Document/Admin/Dokumen.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/user/notification",
        name: "notification",
        component: () => import("../views/Notification/listNotif.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/formulir",
        name: "Formulir",
        component: () => import("../views/Formulir/Index.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/profile",
        name: "profiel",
        component: () => import("../views/Profile/profile.vue"),
        meta: { requiresAuth: true },
      },

      // {
      //   path: "/data",
      //   name: "Data",
      //   component: () => import("../views/Data/Index.vue"),
      //   meta: { requiresAuth: true },
      // },
      {
        path: "/admin/analysis",
        name: "Analisis",
        component: () => import("../views/Analysis/Analysis.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/table/fasyankes",
        name: "Table",
        component: () => import("../views/Table/listDataFasyankes.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/table/user",
        name: "Table",
        component: () => import("../views/Table/listData.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/grafik",
        name: "Grafik",
        component: () => import("../views/Graphic/Index.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/document/user",
        name: "Document",
        component: () => import("../views/Document/Admin/Dokumen.vue"),
        meta: { requiresAuth: true },
      },
      // ROUTER PUBLIC
      {
        path: "/public/document",
        name: "Document",
        component: () => import("../views/Document/public/publicDocument.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/public/about",
        name: "publicAbout",
        component: () => import("../views/About/Public/about.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/public/guide",
        name: "publicGuide",
        component: () => import("../views/Guide/Public/guide.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/about/user",
        name: "userbout",
        component: () => import("../views/About/Public/about.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/guide/user",
        name: "userGuide",
        component: () => import("../views/Guide/Public/guide.vue"),
        meta: { requiresAuth: true },
      },
      // ROUTER ADMIN/konfigurasi
      {
        path: "/admin/public-health-service",
        name: "Public Health Service",
        component: () => import("../views/PublicHealthService/Data.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/user",
        name: "User",
        component: () => import("../views/Pengguna/Admin/listUser.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/dokumen",
        name: "Dokumen Admin Index",
        component: () => import("../views/Document/Admin/index.vue"),
      },
      {
        path: "/admin/about",
        name: "about konfigurasi",
        component: () => import("../views/About/Admin/configurationAbout.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/guide",
        name: "guide konfigurasi",
        component: () => import("../views/About/Admin/configurationGuide.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/formulir",
        name: "formulir konfigurasi",
        component: () => import("../views/Formulir/Admin/formulirConf.vue"),
        meta: { requiresAuth: true },
      },
      // Konfirmasi Dinkes
      {
        path: "/konfirmasi/formulir",
        name: "Konfirmasi Formulir",
        component: () => import("../views/Formulir/Dinkes/Index.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/data-fasyankes",
        name: "data fasyankes",
        component: () => import("../views/dataFasyankes/dataFasyankesConf.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/konfirmasi/formulir/:id",
    name: "Konfirmasi Formulir Fasyankes",
    component: () =>
      import("../views/Formulir/Dinkes/DetailKonfirmasiFasyankes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/konfirmasi/kesling/:id",
    name: "Konfirmasi Formulir Fasyankes",
    component: () =>
      import("../views/Formulir/Dinkes/detailKonfirmasiKesling.vue"),
    meta: { requiresAuth: true },
  },
  // LIMBAH COVID
  {
    path: "/formulir/covid19/:id",
    name: "Formulircovid19",
    component: () => import("../views/Formulir/Fasyankes/limbahCovid19.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/covid19/redaksi/:id",
    name: "FasyankesFormulirredaksi",
    component: () => import("../components/Formulir/redaksiLimbahCovid.vue"),
    meta: { requiresAuth: true },
  },
  // LIMBAH FASYANKES
  {
    path: "/formulir/fasyankes",
    name: "FasyankesFormulir",
    component: () => import("../views/Formulir/Fasyankes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/limbah-fasyankes/:id",
    name: "limbahFasyankes",
    component: () => import("../views/Formulir/Fasyankes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/perbaikan-formulir/limbah-fasyankes/:id",
    name: "limbahFasyankes",
    component: () =>
      import("../views/Formulir/Fasyankes/Perbaikan/perbaikanFasyankes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/limbah-fasyankes/bor/:id",
    name: "Bor limbahFasyankes",
    component: () => import("../views/Formulir/borLimbahFasyankes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/limbah-fasyankes/redaksi/:id",
    name: "FasyankesFormulirredaksi",
    component: () =>
      import("../components/Formulir/redaksiLimbahFasyankes.vue"),
    meta: { requiresAuth: true },
  },
  // ALKES BERMERKURI
  {
    path: "/formulir/alkes-merkuri",
    name: "alkesbermekruki",
    component: () => import("../views/Formulir/Fasyankes/alkesBermekuri.vue"),
    meta: { requiresAuth: true },
  },

  // PERTANYAAN KUNCI
  {
    path: "/formulir/pertanyaan-kunci/:id",
    name: "form-key-environmental-health",
    component: () =>
      import("../views/Formulir/Fasyankes/KeyEnvironmentalHealth/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/pertanyaan-kunci/redaksi/:id",
    name: "pertanyaan",
    component: () =>
      import("../components/Formulir/redaksiPertanyaanKunci.vue"),
    meta: { requiresAuth: true },
  },
  // KESEHATAN LINGKUNGAN
  {
    path: "/formulir/kesehatan-lingkungan/redaksi/:id",
    name: "lingkungan",
    component: () => import("../components/Formulir/redaksiKeslingBiasa.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/kesehatan-lingkungan/:id",
    name: "kesehatan",
    component: () => import("../views/Formulir/Fasyankes/keslingBiasa.vue"),
    meta: { requiresAuth: true },
  },

  // SANITARIAN KIT
  {
    path: "/formulir/sanitarian-kit/redaksi/:id",
    name: "lingkungan",
    component: () => import("../components/Formulir/redaksiSanitarianKit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/formulir/sanitarian-kit/:id",
    name: "kesehatan",
    component: () => import("../views/Formulir/Fasyankes/sanitarianKit.vue"),
    meta: { requiresAuth: true },
  },

  // print
  {
    path: "/print/fasyankes/:id",
    name: "printFasyaankes",
    component: () => import("../components/Formulir/Print/printFasyankes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/print/sanitarian/:id",
    name: "kesehatan",
    component: () => import("../components/Formulir/Print/printSanitarian.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/print/covid/:id",
    name: "kesehatan",
    component: () => import("../components/Formulir/Print/printCovid.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/print/kunci/:id",
    name: "kesehatan",
    component: () =>
      import("../components/Formulir/Print/printKunciKesling.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    name: "notfnd",
    component: () => import("../views/Etc/pageNotFound.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/welcome",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.getUser) {
      const role = store.getters.getUserRole;
      let path = "/";
      if (role === ROLES.HealthServiceFacility) {
        path = "/formulir";
      }

      if (role === ROLES.PublicHealthService_Province) {
        path = "/admin/user";
      }

      if (role === ROLES.PublicHealthService_Regency) {
        path = "/konfirmasi/formulir";
      }

      if (role === ROLES.MinistryOfHealth) {
        path = "/";
      }

      if (role === ROLES.Administrator) {
        path = "/admin/analysis";
      }

      next({
        path,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
