import axios from "axios";
import Cookies from "js-cookie";

// AUTH STUFF
const auth = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // login(context, data) {
    //   return new Promise((resolve, reject) => {
    //     let env = process.env.VUE_APP_ENV;
    //     let url = '';
    //     if (data.sso) {
    //       url = '/api/sso/login';
    //     } else {
    //       url = '/api/login'
    //     }

    //     axios
    //       .post(`${env}${url}`, data)
    //       .then((res) => {
    //         let data = res.data;
    //         Cookies.set("token", data.token, { expires: 7 });
    //         Cookies.set("id", data.data.id, { expires: 7 });
    //         Cookies.set("role", data.data.access_right, { expires: 7 });
    //         Cookies.set("dataAuth", JSON.stringify(data), { expires: 7 });
    //         context.commit("RESPONSE", data);
    //         resolve(res.data)
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         context.commit("ERR_MSG", error.response.data);
    //         reject(error.response.data);
    //       });
    //   });
    // },
    login(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let url = data.sso ? '/api/sso/login' : '/api/login';
    
        // For SSO login, we already have the token, so we can skip the API call
        if (data.sso) {
          Cookies.set("token", data.token, { expires: 7 });
          Cookies.set("id", data.id, { expires: 7 });
          Cookies.set("role", data.role, { expires: 7 });
          // You might want to fetch additional user data here if needed
          context.commit("RESPONSE", { token: data.token, data: { id: data.id, access_right: data.role } });
          resolve({ token: data.token, data: { id: data.id, access_right: data.role } });
          return;
        }
    
        // Existing login logic for non-SSO login
        axios
          .post(`${env}${url}`, data)
          .then((res) => {
            let responseData = res.data;
            Cookies.set("token", responseData.token, { expires: 7 });
            Cookies.set("id", responseData.data.id, { expires: 7 });
            Cookies.set("role", responseData.data.access_right, { expires: 7 });
            Cookies.set("dataAuth", JSON.stringify(responseData), { expires: 7 });
            context.commit("RESPONSE", responseData);
            resolve(responseData)
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    ssoLogin(context, postdata) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/sso`, { postdata })
          .then((res) => {
            let data = res.data;
            if (data.redirect_url) {
              // If we received a redirect_url, we need to redirect the user
              resolve({ redirect: true, url: data.redirect_url });
            } else {
              // If we received user data, we can log the user in
              Cookies.set("token", data.token, { expires: 7 });
              Cookies.set("id", data.data.id, { expires: 7 });
              Cookies.set("role", data.data.access_right, { expires: 7 });
              Cookies.set("dataAuth", JSON.stringify(data), { expires: 7 });
              context.commit("RESPONSE", data);
              resolve({ redirect: false, data: data });
            }
          })
          .catch((error) => {
            console.error(error);
            context.commit("ERR_MSG", error.response?.data || 'SSO login failed');
            reject(error.response?.data || 'SSO login failed');
          });
      });
    },
    
  },
};

export default auth;
