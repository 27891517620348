import axios from "axios";
import Cookies from "js-cookie";

const form = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    checkPreviousMonthForm(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/form/check-previous-month-form`
        );

        Object.entries(request_params).forEach(([key, value]) => {
          request_url.searchParams.append(key, value);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };
        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    fillWithPreviousMonth(context, payload) {
      const { form_type, report_id } = payload;
      return new Promise((resolve, reject) => {
        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        const body = {
          form_type,
          report_id,
        };
        
        axios
          .post(
            `${process.env.VUE_APP_API_BASE_URL}/api/form/fill-with-previous-month`,
            body,
            axios_config
          )
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
};

export default form;
