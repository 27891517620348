export const COLORS = {
  primary_blue: "#00CAE6",
  primary_green: "#00C164",
  primary_yellow: "#FFC822",
  primary_red: "#E84545",
  primary_purple: "#783882",
  primary_pink: "#D8127E",

  green_checkbox: "#11BCC1",

  muted_gray: "#C4CFD9",
};
