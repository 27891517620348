import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const grafik = {
  namespaced: true,
  state: {
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    getData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${data.path}`, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getLimbah({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-covid/limbah-padat", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    // tahun & bulan Limbah Covid-19   limbah-covid/tahun
    getYearCovid({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-covid/tahun", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getTabelProvinsi({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-covid/provinsi", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    //alkes bermerkuri
    getPenghapusan({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/alkes-bermerkuri/penghapusan", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getPenarikan({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/alkes-bermerkuri/penarikan", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getLine({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/provinsi", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getAir({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/air", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getSanitari({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/sanitasi", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getMedis({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/pengelolaan-limbah-medis", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },

    getKebersihan({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/kebersihan-tangan", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getLingkungan({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/kebersihan-lingkungan", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    // /kesehatan-lingkungan/energi-lingkungan
    getEnergi({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/energi-lingkungan", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
    getManagement({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/kesehatan-lingkungan/manajemen-tenaga-kerja", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },

    //sanitarian kit /ketersediaan-alat-ukur-kualitas-udara/alat
    getSanitarian({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/sanitarian-kit/" + data.indikator + '/' + data.type, data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },

    grafikFasyankes({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/provinsi", data)
          .then(function (response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function (error) {
            reject(error.response.data);
          });
      });
    },
  },
};

export default grafik;
