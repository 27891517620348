import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const user = {
  namespaced: true,
  state: {
    errMsg: "",
    province: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
    timbulan: null,
  },
  getters: {},
  mutations: {
    TIMBULAN(state, data) {
      state.timbulan = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // =================11/18/2021===================================================================
    getDataFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        if (data.category) {
          endpoint = `${env}/api/${data.tipe}?tahun=${data.tahun}&tipe=${data.category}`;
        } else {
          endpoint = `${env}/api/${data.tipe}?tahun=${data.tahun}`;
        }
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // API GET LIST DATA SEMUA TIPE KECUALI SANITARIAN
    listDataGeneral(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        // let type = data.filter;
        // if (type == "general") {
        //   endpoint = `${env}/api/data/${data.path}?halaman=${data.limit}&page=${data.page}`;
        // } else if (type == "find") {
        //   endpoint = `${env}/api/data/${data.path}?halaman=${data.limit}&page=1&fil_fasyankes_name=${data.find}`;
        // } else if (type == "role") {
        //   endpoint = `${env}/api/data/${data.path}?halaman=${data.limit}&page=1&tipe_fasyankes=${data.role}`;
        // } else if (type == "limit") {
        //   endpoint = `${env}/api/data/${data.path}?halaman=${data.limit}&page=${data.page}`;
        // } else if (type == "advanced") {
        // }
        endpoint = `${env}/api/data/${data.path}?tipe=get&halaman=${data.limit}&tipe_fasyankes=${data.role}&fil_fasyankes_name=${data.find}&page=${data.page}&fil_provinsi=${data.prov}&fil_kab_kota=${data.city}&fil_blth=${data.date}${
          data.kesehatan_lingkungan ? `&kesehatan_lingkungan=${data.kesehatan_lingkungan}` : ""
        }`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // API GET REKAP DATA
    getRekapData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        endpoint = `${env}/api/data/${data.path}?tipe=rekap&tipe_fasyankes=${data.role}&fil_fasyankes_name=${data.find}&fil_provinsi=${data.prov}&fil_kab_kota=${data.city}&fil_blth=${data.date}${
          data.kesehatan_lingkungan ? `&kesehatan_lingkungan=${data.kesehatan_lingkungan}` : ""
        }`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // GET TOTAL USER
    getTotalUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";

        endpoint = `${env}/api/data/users?provinsi=${data.provinsi}&kabupaten=${data.kabupaten}&roles=${data.roles}`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
      });
    },

    // GET LIST DATA SANITARIAN
    listDataSanitarian(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        let type = data.filter;

        endpoint = `${env}/api/data/${data.path}?record_per_page=${data.limit}&page=${data.page}fil_provinsi=${data.prov}&fil_kab_kota=${data.city}&fil_blth=${data.date}&fil_fasyankes_type=${data.role}&fil_fasyankes_type=${data.role}`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // GET DETAIL
    detailData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        endpoint = `${env}/api/${data.path}?report_id=${data.id}`;
        axios
          .get(endpoint, config)
          .then((res) => {
            
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    profileHeader(context, id) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        endpoint = `${env}/api/detailheader?report_id=${id}`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    timbulanLimbah(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        endpoint = `${env}/api/timbulanlimbahmedis?user_id=${data.user_id}&report_id=${data.report_id}&date=${data.date}`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    timbulanLimbahFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config
        let env = process.env.VUE_APP_ENV
        let endpoint = ""
        endpoint = `${env}/api/timbulanlimbahmedis/limbahfasyankes?user_id=${data.user_id}&report_id=${data.report_id}&date=${data.date}`
        axios.get(endpoint, config)
        .then((res) => {
          let data = res.data
          resolve(data)
        })
        .catch((error) => {
          context.commit("ERR_MSG", error.response.data)
          reject(error.response.data)
        })
      })
    },

    getAlkes(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/get-alkes-bermerkuri`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    // ====================================================================================================================================================
    getProvinces() {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ENV + "/api/data/provinsi/get", {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
              withCredentials: true,
            },
          })
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getLimbah(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;

        let endpoint = "";

        endpoint = `${env}/api/data/get-limbah-fasyankes-by-pagination`;
        axios
          .post(endpoint, data, config)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },

    getDetailFasyankes(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/pewadahan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getPengangkutan({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/pengangkutan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getPemilihan({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/pemilihan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getPenyimpanan({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/penyimpanan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getPengelolaan({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/pengelolaan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getPengolahan({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/pengolahan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getElection({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-fasyankes/pewadahan", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getTahun({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/grafik/limbah-covid/tahun", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },

    downloadData({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ENV + data.url, {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
              withCredentials: true,
              // responseType: "blob",
            },
          })
          .then(function(response) {
            // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            // var fileLink = document.createElement("a");
            // fileLink.href = fileURL;
            // fileLink.setAttribute("download", data.tipeForm + ".xlsx");
            // document.body.appendChild(fileLink);
            // fileLink.click();
            // window.open(response.data.data, '_blank');
            // location.href = response.data.data;
            const res = response.data;
            resolve(res.data);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },

    getDetail({}, data) {
      var url = "";
      if (data.tipeDetail === "limbah-fasyankes") {
        url = "/public/detail-data-limbah-fasyankes?report_id=" + data.report_id;
      }
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ENV + url, {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
              withCredentials: true,
            },
          })
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getCities({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ENV + "/api/data/kab-kota/get-by-provinsi-id", data)
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
    getTableData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        const { url, formData } = data;
        axios
          .post(process.env.VUE_APP_ENV + "/api/data/" + url, formData, {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
              withCredentials: true,
            },
          })
          .then(function(response) {
            const res = response.data;
            resolve(res);
          })
          .catch(function(error) {
            reject(error.response.data);
          });
      });
    },
  },
};

export default user;
