import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const tentang = {
  namespaced: true,
  state: {
    errMsg: "",
    tentang: [],
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_TENTANG(state, data) {
      state.tentang = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    getTentang(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/tentang`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_TENTANG", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateTentang(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/tentang-update/${data.id}`, data.body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateDiagram(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/tentang-diagram-update`, data.body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    createFaq(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/tentang-faq-add`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateFaq(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/tentang-faq-update/${data.id}`, data.body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    deleteFaq(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .delete(`${env}/api/tentang-faq-delete/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    createPanduan(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/tentang-panduan-add`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updatePanduan(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/tentang-panduan-update/${data.id}`, data.body, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    deletePanduan(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .delete(`${env}/api/tentang-panduan-delete/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default tentang;
