import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT Document
const document = {
  namespaced: true,
  state: {
    all_document: '',
    errMsg: "",
    response: "",
    all_user: "",
    person: "",
    profile: "",
    active_user: "",
    unactive_user: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_DOCUMENT(state, data) {
      state.all_document = data.data;
    },
    GET_USER_ACTIVE(state, data) {
      state.active_user = data.data;
    },
    GET_USER_UNACTIVE(state, data) {
      state.unactive_user = data.data;
    },
    GET_PERSON(state, data) {
      state.person = data;
    },
    GET_PROFILE(state, data) {
      state.profile = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    tambahDocument(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/dokumen`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    filterRole(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let status = data.status;
        let endpoint = "";
        if (status) {
          endpoint = `api/get-dokumen/kategori/${status}`;
        } else {
          endpoint = `api/get-dokumen?kategori=${data.body.kategori}&title=${data.body.find}`;
        }
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${endpoint}`, config)
          .then((res) => {
            let data = res;
            context.commit("GET_DOCUMENT", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    filterRole8(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let status = data.status;
        let endpoint = "";
        if (status) {
          endpoint = `api/public-dokumen/kategori/${status}`;
        } else {
          endpoint = `api/public-dokumen?kategori=${data.body.kategori}`;
        }
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${endpoint}`, config)
          .then((res) => {
            let data = res;
            context.commit("GET_DOCUMENT", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateDocument(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/dokumen`,data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    deleteFile(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .delete(`${env}/api/dokumen/${data.dokumen_id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getDocument(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/get-dokumen`, config)
          .then((res) => {
            let data = res;
            context.commit("GET_DOCUMENT", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getDocument8(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/public-dokumen`, config)
          .then((res) => {
            let data = res;
            context.commit("GET_DOCUMENT", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    filterAllinOne(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let status = data.status;
        let params = data.body;
        let endpoint = "";
        let find = params.find;
        if (status) {
          if (find) {
            endpoint = `api/get-dokumen${status}?title=${params.find}`;
          } else {
            endpoint = `api/get-dokumen${status}?page=${params.page}&halaman=${params.limit}`;
          }
        } else {
          if (find) {
            endpoint = `api/get-dokumen?title=${params.find}&kategori=${params.role}`;
          } else {
            endpoint = `api/get-dokumen?page=${params.page}&halaman=${params.limit}&kategori=${params.role}`;
          }
        }
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${endpoint}`, config)
          .then((res) => {
            let data = res;
            context.commit("GET_DOCUMENT", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
        });
    },
    filterAllinOne8(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let status = data.status;
        let params = data.body;
        let endpoint = "";
        let find = params.find;
        if (status) {
          if (find) {
            endpoint = `api/public-dokumen${status}?title=${params.find}`;
          } else {
            endpoint = `api/public-dokumen${status}?page=${params.page}&halaman=${params.limit}`;
          }
        } else {
          if (find) {
            endpoint = `api/public-dokumen?title=${params.find}`;
          } else {
            endpoint = `api/public-dokumen?page=${params.page}&halaman=${params.limit}`;
          }
        }
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/${endpoint}`, config)
          .then((res) => {
            let data = res;
            context.commit("GET_DOCUMENT", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
        });
    },
  },
};

export default document;
