import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const user = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    all_user: "",
    person: "",
    profile: "",
    active_user: "",
    unactive_user: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_USER(state, data) {
      state.all_user = data.data;
    },
    GET_USER_ACTIVE(state, data) {
      state.active_user = data.data;
    },
    GET_USER_UNACTIVE(state, data) {
      state.unactive_user = data.data;
    },
    GET_PERSON(state, data) {
      state.person = data;
    },
    GET_PROFILE(state, data) {
      state.profile = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    createUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/pengguna`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getUser(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/pengguna?page=1&per_page=1`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_USER", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getUserActive(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/pengguna/active`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_USER_ACTIVE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getUserUnactive(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/pengguna/nonactive`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_USER_UNACTIVE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    filterRole(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let status = data.status;
        let endpoint = "";
        if (status) {
          endpoint = `api/pengguna/filterrole/${status}`;
        } else {
          endpoint = `api/pengguna/filterrole`;
        }
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/${endpoint}`, data.body, config)
          .then((res) => {
            let data = res.data;
            if (status == "active") {
              console.log(data);
              context.commit("GET_USER_ACTIVE", data);
            } else if (status == "nonactive") {
              context.commit("GET_USER_UNACTIVE", data);
            } else {
              context.commit("GET_USER", data);
            }
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    filterAllinOne(context, data) {
      return new Promise(async (resolve, reject) => {
        let config = context.state.config;
        let status = data.status;
        const { find, roles, role, limit, page, tipe_fasyankes, province_id } = data.body;
        const params = new URLSearchParams();
    
        // Add roles parameter as an array
        if (roles && Array.isArray(roles)) {
          roles.forEach(role => {
            params.append("roles[]", role);
          });
        } else if (role) {
          params.append("role", role);
        }
    
        if (limit) {
          params.append("limit", limit);
        }
        if (page) {
          params.append("page", page);
        }
        if (find) {
          params.append("find", find);
        }
        if (status) {
          params.append("status", status);
        }
        if (tipe_fasyankes) {
          params.append("tipe_fasyankes", tipe_fasyankes);
        }
        if (province_id) {
          params.append("province_id", province_id);
        }
    
        const request_url = `${process.env.VUE_APP_API_BASE_URL}/api/allfilter?${params.toString()}`;
        // request_url = encodeURI(request_url);
    
        axios
          .get(request_url, config)
          .then((res) => {
            let data = res.data;
            if (status == "active") {
              context.commit("GET_USER_ACTIVE", data);
            } else if (status == "nonactive") {
              context.commit("GET_USER_UNACTIVE", data);
            } else {
              context.commit("GET_USER", data);
            }
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getUserByid(context, id) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/pengguna/${id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_PERSON", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getProfile(context, id) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/pengguna/${id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_PROFILE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/pengguna/${data.id}`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateIdentitas(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/pengguna/updateidentitas/${data.id}`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    deleteUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .delete(`${env}/api/pengguna/${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updateStatusUser(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let id = data.id;
        let env = process.env.VUE_APP_ENV;
        axios
          .put(`${env}/api/pengguna/${data.type}/${id}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    updatePassword(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/pengguna/updatepass`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    resetPassword(context, user_id) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let id = user_id ?? Cookies.get("id");

        axios
          .post(`${env}/api/pengguna/resetpass?id=${id}`, {}, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    changeProfile(context, data) {
      return new Promise((resolve, reject) => {
        // let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let config = {
          header: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };
        axios
          .post(`${env}/api/pupload`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("RESPONSE", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getUsersLastLogin(context, url, config) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        axios
          .get(url, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    createFacilitator(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_API_BASE_URL;
        axios
          .post(`${env}/api/user/create-facilitator`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    }
  },
};

export default user;
