import "regenerator-runtime/runtime.js";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import vueDebounce from 'vue-debounce';
import VueHtml2Canvas from 'vue-html2canvas';
import Vue2Editor from "vue2-editor";
import App from "./App.vue";
import "./assets/css/style.css";
import { COLORS } from "./constants/colors";
import "./plugins/Dayjs";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.use(VueHtml2Canvas);
Vue.use(Vue2Editor);
Vue.use(VueApexCharts);
Vue.use(vueDebounce, {
  defaultTime: '500ms',
})
Vue.component("Apexchart", VueApexCharts);
Vue.config.productionTip = false;
Vue.prototype.$isMobile = function () {
  return window.innerWidth <= 600;
}
Vue.prototype.$getStatusColorHex = function(status) {
  let color_hex = "";
  if (status == 0) {
    color_hex = "#F6C435";
  } else if (status == 1) {
    color_hex = "#D1D1D1";
  } else if (status == 2) {
    color_hex = "#43ACC3";
  } else if (status == 3) {
    color_hex = "#4DC6AF";
  } else {
    color_hex = "#DB232D";
  }

  return color_hex;
}

Vue.prototype.$getReportStatusFormatted = function(status) {
  let status_formatted = "";
  if (status == 0) {
    status_formatted = "Formulir Belum Terkirim";
  } else if (status == 1) {
    status_formatted = "Formulir Telah Terkirim";
  } else if (status == 2) {
    status_formatted = "Formulir Perlu Perbaikan";
  } else if (status == 3) {
    status_formatted = "Formulir Telah Terkirim dan Terkonfirmasi";
  } else {
    status_formatted = "Formulir Tidak Terisi";
  }

  return status_formatted;
};

Vue.prototype.$getReportStatusTranslation = function(status) {
  let status_translation = "";
  if (status == 'base_service') {
    status_translation = "layanan dasar";
  } else if (status == 'limited_service') {
    status_translation = "layanan terbatas";
  } else if (status == 'plenary_service') {
    status_translation = "layanan paripurna";
  } else {
    status_translation = "tidak ada layanan";
  }

  return status_translation;
}

Vue.prototype.$getReportStatusColor = function(status) {
  let status_color = "";
  if (status == "base_service") {
    status_color = COLORS.primary_green;
  } else if (status == "limited_service") {
    status_color = COLORS.primary_yellow;
  } else if (status == "plenary_service") {
    status_color = COLORS.primary_blue;
  } else {
    status_color = COLORS.primary_red;
  }

  return status_color;
};

Vue.prototype.$getVerificationStatusFormatted = function(verification_status) {
  if (verification_status == 0) {
    return "Belum Terkirim";
  } else if (verification_status == 1) {
    return "Terkirim";
  } else if (verification_status == 2) {
    return "Terkirim dan Terkonfirmasi";
  } else if (verification_status == 3) {
    return "Perlu Perbaikan";
  } else {
    return "Tidak Terisi";
  }
}

Vue.prototype.$monthTranslation = function(month) {
  const month_lowercase = month.toLowerCase();
  if (month_lowercase == "january") { 
    return "Januari";
  } else if (month_lowercase == "february") {
    return "Februari";
  } else if (month_lowercase == "march") {
    return "Maret";
  } else if (month_lowercase == "april") {
    return "April";
  } else if (month_lowercase == "may") {
    return "Mei";
  } else if (month_lowercase == "june") {
    return "Juni";
  } else if (month_lowercase == "july") {
    return "Juli";
  } else if (month_lowercase == "august") {
    return "Agustus";
  } else if (month_lowercase == "september") {
    return "September";
  } else if (month_lowercase == "october") {
    return "Oktober";
  } else if (month_lowercase == "november") {
    return "November";
  } else if (month_lowercase == "december") {
    return "Desember";
  } else {
    return "";
  }
}

  
  

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
