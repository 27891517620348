<template>
  <div>
    <publicSidebar v-if="!token" />
    <AdminSidebar
      v-if="role == 'Administrator'"
      :unread_notifications_count="unread_notifications_count"
    />
    <KemenkesSidebar
      v-if="role == 'Kemenkes'"
      :unread_notifications_count="unread_notifications_count"
    />
    <EksternalSidebar
      v-if="role == 'Eksternal'"
      :unread_notifications_count="unread_notifications_count"
    />
    <FaysankesSidebar
      v-if="role == 'Fasyankes'"
      :unread_notifications_count="unread_notifications_count"
    />
    <DinkesKabSidebar
      v-if="role == 'Dinkes Kota'"
      :unread_notifications_count="unread_notifications_count"
    />
    <DinkesProvSidebar
      v-if="role == 'Dinkes Provinsi'"
      :unread_notifications_count="unread_notifications_count"
    />
    <div>
      <router-view
        v-if="isMobile"
        class="bg_main_mobile full_h"
      />
      <router-view
        v-if="!isMobile"
        class="bg_main full_h"
      />
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import AdminSidebar from "../components/Sidebar/adminSidebar.vue";
import DinkesKabSidebar from "../components/Sidebar/dinkesKabSidebar.vue";
import DinkesProvSidebar from "../components/Sidebar/dinkesProvSidebar.vue";
import FaysankesSidebar from "../components/Sidebar/faysankesSidebar.vue";
import KemenkesSidebar from "../components/Sidebar/kemenkesSidebar.vue";
import publicSidebar from "../components/Sidebar/publicSidebar.vue";
import EksternalSidebar from "../components/Sidebar/eksternalSidebar.vue";
export default {
  name: "HomeComponent",
  components: {
    publicSidebar,
    AdminSidebar,
    KemenkesSidebar,
    FaysankesSidebar,
    DinkesKabSidebar,
    DinkesProvSidebar,
    EksternalSidebar,
  },
  data() {
    return {
      loading: false,
      isMobile: false,
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      role: (state) => state.role,
      unread_notifications_count: (state) =>
        state.notifikasi.unread_notifications_count,
    }),
  },
  async mounted() {
    await this.getProfile();
    if (this.token) {
      this.getNotifications();
    }
    this.checkMobile();
    if (this.token) {
      this.getNotifications();
    }
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 600; 
    },
    async getProfile() {
      this.loading = true;
      if (this.role) {
        let id = Cookies.get("id");
        this.$store
          .dispatch("user/getProfile", id)
          .then((data) => {
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.Swal("error", err.message);
          });
      }
    },
    async getNotifications() {
      this.loading = true;
      let body = {
        tipeNotif: "unread",
      };

      try {
        const res = await this.$store.dispatch(
          "notifikasi/getNotifikasi",
          body
        );
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
  },
};
</script>
