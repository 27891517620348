const globalUI = {
    namespaced: true,
    state: {
        page: {
            table: {
                selected_tab: 'environmental-health-key',
            }
        }
    },
    mutations: {
        SET_PAGE_TABLE_SELECTED_TAB(state, payload) {
            state.page.table.selected_tab = payload
        }
    },
    actions: {
        setPageTableSelectedTab(context, payload) {
            context.commit('SET_PAGE_TABLE_SELECTED_TAB', payload)
        }
    },
}

export default globalUI;