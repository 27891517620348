<template>
  <v-navigation-drawer
    :color="color"
    :permanent="true"
    app
    floating
    style="padding: 0"
    class="scroller"
  >
    <div>
      <div class="p-20 d-flex justify-center align-center white--text">
        <img
          src="../../assets/img/logo_kesling.png"
          class="mr-3"
          height="50px"
          alt=""
        >
      </div>
      <div
        v-if="profile"
        class="p-20 mb-5 d-flex"
      >
        <div
          class="frame_img d-flex align-center justify-center"
          style="width: 100px"
        >
          <img
            :src="dummy"
            class="pp"
            alt=""
            style="border-radius: 50px; width: 48px"
            height="50px;"
          >
        </div>
        <div class="white--text">
          <p class="small_txt ma-0">
            Administrator
          </p>
          <p class="small_txt ma-0">
            <b>{{ profile.full_name }}</b>
          </p>
          <router-link
            to="/profile"
            style="text-decoration: none"
          >
            <p class="small_txt ma-0 white--text">
              profil
            </p>
          </router-link>
        </div>
      </div>

      <div v-if="!configMenu">
        <div
          v-for="(item, idx) in menu"
          :key="idx"
          class="sidebar_item mb-1"
        >
          <router-link
            :to="item.path"
            style="text-decoration: none"
          >
            <div
              :class="
                $route.path == item.path ? 'sidebar_active' : 'sidebar_unactive'
              "
            >
              <v-icon
                class="mr-5"
                :color="$route.path == item.path ? '#00b4cc' : 'white'"
                :dark="false"
              >
                {{ item.icon }}
              </v-icon>
              <p class="ma-0 hovering_txt">
                {{ item.name }}
              </p>
            </div>
          </router-link>
        </div>
        <div
          class="sidebar_item mb-1"
          @click="configMenu = true"
        >
          <div class="sidebar_unactive">
            <v-icon
              class="mr-5"
              color="white"
            >
              mdi-tune
            </v-icon>
            <p class="ma-0 hovering_txt">
              Konfigurasi
            </p>
          </div>
        </div>
      </div>

      <div v-if="configMenu">
        <div class="sidebar_item mb-1">
          <div class="sidebar_unactive">
            <v-icon
              class="mr-5"
              color="white"
            >
              mdi-tune
            </v-icon>
            <p class="ma-0 hovering_txt">
              Konfigurasi
            </p>
          </div>
        </div>
        <div
          v-for="(item, idx) in submenu"
          :key="idx"
          class="sidebar_subitem mb-1"
        >
          <router-link
            :to="item.path"
            style="text-decoration: none"
          >
            <div
              :class="
                $route.path == item.path ? 'sidebar_active' : 'sidebar_unactive'
              "
            >
              <v-icon
                class="mr-3"
                :color="$route.path == item.path ? '#00b4cc' : 'white'"
                :dark="false"
              >
                {{ item.icon }}
              </v-icon>
              <p class="ma-0 hovering_txt">
                {{ item.name }}
              </p>
            </div>
          </router-link>
        </div>
        <div
          class="sidebar_item mb-1"
          @click="configMenu = false"
        >
          <div class="sidebar_unactive">
            <v-icon
              class="mr-5"
              color="white"
            >
              mdi-view-dashboard-outline
            </v-icon>
            <p class="ma-0 hovering_txt">
              Menu Lainya
            </p>
          </div>
        </div>
      </div>
      <div class="pa-3">
        <v-divider style="background: #fff" />
      </div>
      <div class="sidebar_item mb-1">
        <router-link
          to="/user/notification"
          style="text-decoration: none"
        >
          <div :class=" $route.path == '/user/notification' ? 'sidebar_active' : 'sidebar_unactive' ">
            <NotificationBadge :on_focus="$route.path == '/user/notification'" />
            <p class="ma-0 hovering_txt">
              Notifikasi
            </p>
          </div>
        </router-link>
      </div>
      <div class="sidebar_item mb-1">
        <div
          class="sidebar_unactive"
          @click="logout"
        >
          <v-icon
            class="mr-5"
            color="white"
          >
            mdi-logout-variant
          </v-icon>
          <p class="ma-0 hovering_txt">
            Keluar
          </p>
        </div>
      </div>
    </div>

    <div class="poweredTxtSidebar">
        <p class="ml-8" style="margin-bottom: 4px;">
          Powered by piarea.co.id, <br>
          supported by WHO Indonesia
        </p>
        <span style="font-weight: 600;" class="text-left ml-8 ">
          SIKELIM v1.3
        </span>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import NotificationBadge from "../../components/menu/NotificationBadge";

export default {
  name: "AdminSidebar",
  components: {
    NotificationBadge,
  },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      dummy: (state) => state.imgDummy,
    }),
  },
  data() {
    return {
      configMenu: false,
      color: "#00b4cc",
      menu: [
        {
          name: "Analisis",
          path: "/admin/analysis",
          icon: "mdi-chart-box",
        },
        {
          name: "Peta",
          path: "/",
          icon: "mdi-map-outline",
        },
        {
          name: "Grafik",
          path: "/grafik",
          icon: "mdi-chart-pie-outline",
        },
        {
          name: "Tabel",
          path: "/table/user",
          icon: "mdi-chart-box-outline",
        },
        {
          name: "Dokumen",
          path: "/admin/document",
          icon: "mdi-cloud-download-outline",
        },
        {
          name: "Tentang",
          path: "/about/user",
          icon: "mdi-information-outline",
        },
        {
          name: "Panduan",
          path: "/guide/user",
          icon: "mdi-information-outline",
        },
      ],
      submenu: [
        {
          name: "Formulir",
          path: "/admin/formulir",
          icon: "mdi-cog",
        },
        {
          name: "Akun",
          path: "/admin/user",
          icon: "mdi-cog",
        },
        {
          name: "Data Dinas Kesehatan",
          path: "/admin/public-health-service",
          icon: "mdi-cog",
        },
        {
          name: "Data Fasyankes",
          path: "/admin/data-fasyankes",
          icon: "mdi-cog",
        },
        {
          name: "Dokumen",
          path: "/admin/dokumen",
          icon: "mdi-cog",
        },
        {
          name: "Tentang",
          path: "/admin/about",
          icon: "mdi-cog",
        },
        {
          name: "Panduan",
          path: "/admin/guide",
          icon: "mdi-cog",
        },
      ],
      dialogLogin: false,
    };
  },
  mounted() {
    this.getPhotoProfile();
  },
  methods: {
    getPhotoProfile() {
      this.$store.dispatch("getPhotoProfile").then((data) => {});
    },
    showLogin() {
      this.dialogLogin = true;
    },
    logout() {
      Cookies.remove("token");
      Cookies.remove("id");
      Cookies.remove("role");
      this.$router.go("/welcome");
    },
    swipeConfig() {},
  },
};
</script>

<style scoped>
.poweredTxtSidebar {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #EFF2F8;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.poweredTxtSidebar p {
  font-weight: 300;
  text-align: left;
  
}
.sidebar_item {
  padding-left: 20px;
}
.sidebar_subitem {
  padding-left: 30px;
}
.sidebar_active {
  display: flex;
  padding: 15px 20px;
  border-radius: 10px 0 0 10px;
  background: white;
  color: #00b4cc;
  cursor: pointer;
}
.sidebar_unactive {
  display: flex;
  padding: 17px 20px;
  border-radius: 10px 0 0 10px;
  color: #fff;
  cursor: pointer;
}
.logo {
  padding: 20px;
}
.p-20 {
  padding: 20px;
}
.hovering_txt:hover {
  font-weight: bold;
}
::-webkit-scrollbar {
  width: 10px;
}
.dp {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
  background: #6cd8e6;
}
</style>
