import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const map = {
  namespaced: true,
  state: {
    errMsg: "",
    coor: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_MAP(state, data) {
      state.coor = data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    getCoor(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        // ?kota_id=${data}
        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/map?kota_id=${data}`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_MAP", data);
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    percentForColor(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        axios
          .get(
            `${env}/api/map/warnamap?bulan=${data.bulan}&tahun=${data.tahun}&provinsi_id=${data.provinsi_id}`,
            config
          )
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getGrafikBeranda(context, data) {
      return new Promise((resolve, reject) => {
        // let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let type = data.type;
        let formulir = data.formulir;
        let body = data.body;
        let endpoint = "";
        if (formulir == "limbah-fasyankes") {
          endpoint = `${env}/api/grafik/${formulir}/${type}`;
        } else if (formulir == "kesehatan-lingkungan") {
          endpoint = `${env}/api/grafik/${formulir}/${type}`;
        }
        axios
          .post(endpoint, body)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getGrafikCovid(context, data) {
      return new Promise((resolve, reject) => {
        // let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        console.log(data);
        let endpoint = "";
        endpoint = `${env}/api/grafikberanda/limbahcovid?tanggal=${data.date}&tipe_fasyankes=${data.tipe_fasyankes}`;
        axios
          .get(endpoint)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getGrafikMerkuri(context, data) {
      return new Promise((resolve, reject) => {
        // let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        endpoint = `${env}/api/grafikberanda/alkesbermerkuri?bulan=${data.bulan}&tahun=${data.tahun}`;
        axios
          .get(endpoint)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getGrafiKesling(context, data) {
      return new Promise((resolve, reject) => {
        // let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";

        endpoint = `${env}/api/grafikberanda/kesling?kategori=${data.type}&bulan=${data.bulan}&tahun=${data.tahun}&tipe_fasyankes=${data.tipe_fasyankes}`;

        if (data.hasOwnProperty("provinsi_id")) {
          if (data.provinsi_id != null) {
            endpoint += `&provinsi_id=${data.provinsi_id}`;
          }
        }

        if (data.hasOwnProperty("kabupatenKota_id")) {
          if (data.kabupatenKota_id != null) {
            endpoint += `&kabupatenKota_id=${data.kabupatenKota_id}`;
          }
        }

        axios
          .get(endpoint)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // kalo klik marker maka akan ambil :
    getFromMarker(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        // ${data.id}
        if (data.tipe == "pengelolaan") {
          endpoint = `${env}/api/grafikberanda/marker?bulan=${data.bulan}&tahun=${data.tahun}&lokasi=${data.fasyankes}&id_fasyankes=${data.id}`;
        } else {
          endpoint = `${env}/api/grafikberanda/limbahfasyankes?bulan=${data.bulan}&tahun=${data.tahun}&lokasi=${data.fasyankes}&id_fasyankes=${data.id}&tipe=${data.tipe}`;
        }
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    // kalo klik marker maka akan ambil :
    getNameRs(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = process.env.VUE_APP_ENV;
        let endpoint = "";
        endpoint = `${env}/api/grafikberanda/namafasyankes?lokasi=${data.fasyankes}&id_fasyankes=${data.id}`;
        axios
          .get(endpoint, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default map;
