import axios from "axios";
import Cookies from "js-cookie";

// MANAGEMENT USER
const instansi = {
  namespaced: true,
  state: {
    errMsg: "",
    province: "",
    city: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    GET_PROVINCE(state, data) {
      state.province = data.data;
    },
    GET_CITY(state, data) {
      state.city = data.data;
    },
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    getProvince(context) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;
        axios
          .get(`${env}/api/data/provinsi/get`, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_PROVINCE", data);
            resolve(res.data);
            console.log("Province : ", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    getCity(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;

        let env = process.env.VUE_APP_ENV;

        axios
          .post(`${env}/api/data/kab-kota/get-by-provinsi-id`, data, config)
          .then((res) => {
            let data = res.data;
            context.commit("GET_CITY", data);
            resolve(res.data);
            console.log("City : ", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
  },
};

export default instansi;
