import axios from "axios";
import Cookies from "js-cookie";

const report = {
  namespaced: true,
  state: {
    report: {},
    waste_report: {
      service_statuses: {
        sorting: false,
        packaging: false,
        transportation: false,
        storage: false,
        processing: false,
        management: false,
      },
    },
    monthly_summary: {
      achievements: []
    },
    users_monthly_report: {},
    environmental_health_key_report: {},
    environmental_health_key_report_analysis: {},
    waste_reports: {},
    waste_reports_analysis: {},
    user_yearly_report: [],
    users_waste_yearly_report: [],
    waste_monthly_summary: {
      achievements: []
    },
    users_yearly_waste_emergence: [],
    waste_emergence_analysis: {},
    waste_emergence_summary: {}
  },
  mutations: {
    SET_USERS_MONTHLY_REPORT(state, data) {
      state.users_monthly_report = data;
    },
    SET_ENVIRONMENTAL_HEALTH_KEY_REPORT(state, data) {
      state.environmental_health_key_report = data;
    },
    SET_ENVIRONMENTAL_HEALTH_KEY_REPORT_ANALYSIS(state, data) {
      state.environmental_health_key_report_analysis = data;
    },
    SET_WASTE_REPORT(state, data) {
      state.waste_report = data;
    },
    SET_MONTHLY_SUMMARY(state, data) {
      state.monthly_summary = data;
    },
    SET_WASTE_MONTHLY_SUMMARY(state, data) {
      state.waste_monthly_summary = data;
    },
    SET_REPORT(state, data) {
      state.report = data;
    },
    SET_WASTE_REPORTS(state, data) {
      state.waste_reports = data;
    },
    SET_WASTE_REPORTS_ANALYSIS(state, data) {
      state.waste_reports_analysis = data;
    },
    SET_USER_YEARLY_REPORT(state, data) {
      state.user_yearly_report = data;
    },
    SET_USER_WASTE_YEARLY_REPORT(state, data) {
      state.users_waste_yearly_report = data;
    },
    SET_USER_YEARLY_WASTE_EMERGENCE(state, data) {
      state.users_yearly_waste_emergence = data;
    },
    SET_WASTE_EMERGENCE_ANALYSIS(state, data) {
      state.waste_emergence_analysis = data;
    },
    SET_WASTE_EMERGENCE_SUMMARY(state, data) {
      state.waste_emergence_summary = data;
    }
  },
  actions: {
    getEnvironmentalHealthKeyReport(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/environmental-health-key`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_ENVIRONMENTAL_HEALTH_KEY_REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getEnvironmentalHealthKeyReportAnalysis(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/environmental-health-key/analysis`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit(
              "SET_ENVIRONMENTAL_HEALTH_KEY_REPORT_ANALYSIS",
              data
            );
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getWasteManagementReports(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_WASTE_REPORTS", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getWasteManagementReportsAnalysis(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/analysis`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_WASTE_REPORTS_ANALYSIS", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getUsersMonthlyReport(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/environmental-health-key/monthly`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_USERS_MONTHLY_REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    getMonthlySummary(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/environmental-health-key/monthly-summary`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_MONTHLY_SUMMARY", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getWasteManagementMonthlySummary(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/monthly-summary`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_WASTE_MONTHLY_SUMMARY", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getReportById(context, report_id) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/environmental-health-key/${report_id}`
        );

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getUserYearlyReport(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/environmental-health-key/user-yearly`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_USER_YEARLY_REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getWasteManagementReportById(context, report_id) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/${report_id}`
        );

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_WASTE_REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getUserWasteYearlyReports(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/user-yearly`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_USER_WASTE_YEARLY_REPORT", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getUserYearlyWasteEmergence(context, payload) {
      const { user_id, year } = payload;
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/${user_id}/yearly-waste-emergence`
        );

        request_url.searchParams.append("year", year);
        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_USER_YEARLY_WASTE_EMERGENCE", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getWasteEmergenceAnalysisReports(context, request_params) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(
          `${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/waste-emergence-analysis`
        );

        Object.keys(request_params).forEach((key) => {
          request_url.searchParams.append(key, request_params[key]);
        });

        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };

        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_WASTE_EMERGENCE_ANALYSIS", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getWasteEmergenceSummary(context) {
      return new Promise((resolve, reject) => {
        let request_url = new URL(`${process.env.VUE_APP_API_BASE_URL}/api/report/waste-management/waste-emergence-summary`);
        
        const axios_config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        };
        axios
          .get(request_url, axios_config)
          .then((res) => {
            const data = res.data;
            context.commit("SET_WASTE_EMERGENCE_SUMMARY", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    download(context, payload) {
      return new Promise((resolve, reject) => {
        const { province_id, regency_id, year, period_type, period_value, form_type } = payload;
      let request_url = new URL(`${process.env.VUE_APP_API_BASE_URL}/api/report/download`);

      const axios_config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          withCredentials: true,
        },
      };

      let body = {
        province_id,
        regency_id,
        year,
        period_type,
        period_value,
        form_type
      };

      axios
        .post(request_url, body, axios_config)
        .then((res) => {
          const data = res.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
      });
    },
  },
};

export default report;
